import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import DeelnemerService from "../../../Services/DeelnemerService";
import SessionHandler from "../../../Sessionhandler/SessionHandler";
import EvenementenService from "../../../Services/EvenementenService";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EventDetails() {
  const [event, setEvent] = useState(null);
  const [deelnemers, setDeelnemers] = useState([]);
  const [error, setError] = useState(null);
  const [userRol, setUserRol] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const query = useQuery();
  const eventId = query.get("id");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}:${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}:${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  };

  const hasPassedEndDate = () => {
    const currentDate = new Date();
    const eventEndDate = new Date(event.einddatumtijd);
    return (
      currentDate > eventEndDate &&
      (currentDate - eventEndDate) / (1000 * 60 * 60 * 24) > 1
    );
  };

  const fetchEventDetails = async () => {
    try {
      const eventDetails = await EvenementenService.GetByID(eventId);
      setEvent(eventDetails.data.payload);
    } catch (err) {
      setError("Error fetching event details");
    }
  };

  const fetchDeelnemers = async () => {
    try {
      setRefreshing(true);
      const result = await DeelnemerService.GetDeelnemers(eventId);
      if (result.type === "SUCCES") {
        setDeelnemers(result.payload);
        setError(null);
      } else {
        setError(result.details);
      }
    } catch (err) {
      setError("Error fetching deelnemers");
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    setUserRol(SessionHandler.GetCurrentRol());
    if (eventId) {
      fetchEventDetails();
      fetchDeelnemers();
    }
  }, [eventId]);

  const updateAanwezigheid = useCallback(
    async (deelnemerId, eventId, status) => {
      try {
        await DeelnemerService.UpdateAanwezigheid(deelnemerId, eventId, status);
        await fetchDeelnemers();
      } catch (err) {
        setError("Error updating status");
      }
    },
    [fetchDeelnemers]
  );

  const renderDeelnemers = (status, label, borderColor) => (
    <div>
      <div className="status-header">
        <h3>{label}</h3>
        {(userRol === "trainer" ||
          userRol === "bestuur" ||
          userRol === "hoofd-trainer") &&
          !hasPassedEndDate() && (
            <span className="instruction">Geef opkomst aan</span>
          )}
      </div>
      {deelnemers
        .filter((deelnemer) => deelnemer.aangemeld === status)
        .map((deelnemer) => (
          <div key={deelnemer.id} className="deelnemer" style={{ borderColor }}>
            <span>{`${deelnemer.voornaam} ${deelnemer.achternaam}`}</span>
            {(userRol === "trainer" ||
              userRol === "bestuur" ||
              userRol === "hoofd-trainer") &&
              !hasPassedEndDate() && (
                <div className="button-group">
                  <button
                    style={{ backgroundColor: "green" }}
                    disabled={deelnemer.opkomendagen === 1}
                    onClick={() => updateAanwezigheid(deelnemer.id, eventId, 1)}
                  >
                    ✓
                  </button>
                  <button
                    style={{ backgroundColor: "red" }}
                    disabled={deelnemer.opkomendagen === 2}
                    onClick={() => updateAanwezigheid(deelnemer.id, eventId, 2)}
                  >
                    ✗
                  </button>
                </div>
              )}
          </div>
        ))}
    </div>
  );

  if (!event) {
    return <div>Geen Evenement Gevonden</div>;
  }

  return (
    <div className="event-container">
      <h1>{event.titel}</h1>
      <p>
        <strong>Start:</strong> {formatDate(event.startdatumtijd)}
      </p>
      <p>
        <strong>Eind:</strong> {formatDate(event.einddatumtijd)}
      </p>
      <p>
        <strong>Beschrijving:</strong> {event.text}
      </p>

      {error && <div className="error">{error}</div>}

      {renderDeelnemers(1, "Aangemeld", "green")}
      {renderDeelnemers(0, "Niks opgegeven", "yellow")}
      {renderDeelnemers(2, "Afgemeld", "red")}

      <button
        className="refresh-btn"
        onClick={fetchDeelnemers}
        disabled={refreshing}
      >
        {refreshing ? "Refreshing..." : "Refresh"}
      </button>

      <style jsx>{`
        .event-container {
          max-width: 800px;
          margin: auto;
          padding: 20px;
          background-color: #f9f9f9;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        h1 {
          font-size: 24px;
          text-align: center;
          color: #333;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          color: #555;
        }

        .status-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          margin-top: 20px;
          padding-bottom: 5px;
          border-bottom: 2px solid #ddd;
        }

        .instruction {
          font-size: 14px;
          font-style: italic;
          color: #888;
        }

        .deelnemer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          margin: 10px 0;
          border: 2px solid;
          border-radius: 6px;
          background-color: #fff;
        }

        .button-group {
          display: flex;
          gap: 10px;
        }

        button {
          padding: 8px 12px;
          border: none;
          cursor: pointer;
          border-radius: 4px;
          font-size: 14px;
          color: white;
        }
        button:disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        .refresh-btn {
          background-color: #007bff;
          color: white;
          padding: 10px 20px;
          margin-top: 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }

        .refresh-btn:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }

        .error {
          color: red;
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
}

export default EventDetails;
