import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Use react-router for navigation
import DeelnemerService from "../../../Services/DeelnemerService";
import SessionHandler from "../../../Sessionhandler/SessionHandler";
import jwtDecode from "jwt-decode";

function Evenementen() {
  const [evenementen, setEvenementen] = useState([]);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const scrollViewRef = useRef(null); // Reference for the ScrollView (can be used for scrolling)
  const navigate = useNavigate(); // Initialize navigation

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (await SessionHandler.checklogin()) {
          const token = await SessionHandler.getToken();

          if (token) {
            try {
              const decodedToken = jwtDecode(token);
              const resolvedUserId = decodedToken.userId;
              setUserId(resolvedUserId);
              await getEvents(resolvedUserId);
            } catch (decodeError) {
              setError("Error decoding JWT token:", decodeError);
            }
          } else {
            setError("JWT token not available");
          }
        } else {
          setError("User is not logged in");
        }
      } catch (fetchError) {
        console.error("Error fetching events:", fetchError);
        setError("An error occurred while fetching events.");
      }
    };

    fetchData();
  }, []);

  const getEvents = async (userId) => {
    try {
      const result = await DeelnemerService.GetByUserID(userId);

      if (result.type === "SUCCES") {
        const sortedEvents = result.payload.sort((a, b) => {
          const eventA = new Date(`${a.startdatumtijd}`);
          const eventB = new Date(`${b.startdatumtijd}`);
          return eventA - eventB;
        });

        setEvenementen(sortedEvents);
        setError(null);
      } else {
        navigate("/connection-error", { state: { Context: "Evenementen" } });
        setError(result.details);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setError("An error occurred while fetching events.");
      navigate("/connection-error", { state: { Context: "Evenementen" } });
    }
  };

  const reloadPage = () => {
    if (userId !== null) {
      getEvents(userId);
    }
  };

  const handleAfmelden = (eventId, e) => {
    e.stopPropagation(); // Prevent navigation on button click
    if (userId !== null) {
      DeelnemerService.UpdateAangemeld(userId, eventId, 2);
      setEvenementen((prevEvenementen) =>
        prevEvenementen.map((event) =>
          event.id === eventId ? { ...event, aangemeld: 2 } : event
        )
      );
    }
  };

  const handleAanmelden = (eventId, e) => {
    e.stopPropagation(); // Prevent navigation on button click
    if (userId !== null) {
      DeelnemerService.UpdateAangemeld(userId, eventId, 1);
      setEvenementen((prevEvenementen) =>
        prevEvenementen.map((event) =>
          event.id === eventId ? { ...event, aangemeld: 1 } : event
        )
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}:${month}:${year} - ${hours}:${minutes}`;
  };

  const firstFutureEventIndex = evenementen.findIndex(
    (event) => new Date(event.startdatumtijd) >= new Date()
  );

  const handleEventPress = (event) => {
    navigate(`/event-details?id=${event.id}`);
    // Navigate to EventDetails page with event data
  };

  useEffect(() => {
    if (scrollViewRef.current && firstFutureEventIndex !== -1) {
      setTimeout(() => {
        scrollViewRef.current.scrollTo({
          top: firstFutureEventIndex * 200, // Adjust the multiplier depending on item height
          behavior: "smooth",
        });
      });
    }
  }, [evenementen]);

  return (
    <div style={styles.container}>
      <div
        style={styles.scrollViewContainer}
        ref={scrollViewRef}
        className="event-list"
      >
        <div style={styles.eventList}>
          {error ? (
            <p>Error: {error}</p>
          ) : evenementen.length === 0 ? (
            <p>No events available</p>
          ) : (
            evenementen.map((event, index) => (
              <React.Fragment key={`${event.id}-${index}`}>
                {index === firstFutureEventIndex && (
                  <div style={styles.separator} />
                )}
                <div
                  style={{
                    ...styles.eventContainer,
                    backgroundColor:
                      new Date(event.startdatumtijd) < new Date()
                        ? event.aangemeld === 1
                          ? "green"
                          : event.aangemeld === 2
                          ? "rgba(255, 0, 0, 0.7)"
                          : "grey"
                        : null,
                  }}
                  onClick={() => handleEventPress(event)} // Navigate on event click
                >
                  <h3 style={styles.eventName}>{event.titel}</h3>
                  <p style={styles.eventDateTime}>
                    {"Start: " + formatDate(event.startdatumtijd)}
                  </p>
                  <p style={styles.eventDateTime}>
                    {"Eind:  " + formatDate(event.einddatumtijd)}
                  </p>
                  {new Date(event.startdatumtijd) >= new Date() && (
                    <div style={styles.buttonContainer}>
                      <div style={styles.buttonWrapper}>
                        {event.aangemeld === 1 && (
                          <button
                            className="btn btn-secondary"
                            onClick={(e) => handleAfmelden(event.id, e)}
                            style={{ backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                          >
                            Afmelden
                          </button>
                        )}
                      </div>
                      <div style={styles.buttonWrapper}>
                        {event.aangemeld === 2 && (
                          <button
                            className="btn btn-secondary"
                            onClick={(e) => handleAanmelden(event.id, e)}
                            style={{ backgroundColor: "green" }}
                          >
                            Aanmelden
                          </button>
                        )}
                      </div>
                      <div style={styles.buttonWrapper}>
                        {event.aangemeld === 0 && (
                          <button
                            className="btn btn-secondary"
                            onClick={(e) => handleAanmelden(event.id, e)}
                            style={{ backgroundColor: "green" }}
                          >
                            Aanmelden
                          </button>
                        )}
                      </div>
                      <div style={styles.buttonWrapper}>
                        {event.aangemeld === 0 && (
                          <button
                            className="btn btn-secondary"
                            onClick={(e) => handleAfmelden(event.id, e)}
                            style={{ backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                          >
                            Afmelden
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  scrollViewContainer: {
    width: "100%",
    maxHeight: "80vh",
    overflowY: "scroll",
    paddingTop: "20px",
  },
  eventList: {
    width: "100%",
    display: "flex",
    flexDirection: "column", // Change from row to column to stack events
    justifyContent: "center",
    alignItems: "center",
  },
  eventContainer: {
    width: "90%",
    minHeight: "150px",
    padding: "10px",
    marginBottom: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column", // Stack contents vertically within each event
    justifyContent: "space-between",
  },
  eventName: {
    fontSize: "18px",
    marginBottom: "5px",
  },
  eventDateTime: {
    fontSize: "20px",
    color: "#0c0c0c",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column", // Stack buttons vertically
    marginTop: "10px",
  },
  buttonWrapper: {
    marginBottom: "10px", // Add space between buttons
  },
  reloadButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 1,
  },
  separator: {
    width: "90%",
    height: "2px",
    backgroundColor: "#0c0c0c",
    marginVertical: "20px",
  },
};

export default Evenementen;
