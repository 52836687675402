import axios from 'axios'
import SessionHandler from '../Sessionhandler/SessionHandler'
axios.defaults.headers.common.Authorization = 'Bearer ' + SessionHandler.getToken()

const DEELNEMER_API_BASE_URL = process.env.REACT_APP_API_URL + 'Deelnemer'
axios.defaults.headers.common['ClubID'] = SessionHandler.GetClub();
class DeelnemerService {
  GetDeelnemers (EvenementID) {
    return axios.get(DEELNEMER_API_BASE_URL + '/getdeelnemers/' + EvenementID)
  }

  async GetByUserID(ID) {
    try {
      // Fetch the token using SessionHandler
      const token = SessionHandler.getToken();
      const club =  SessionHandler.GetClub();
      console.log("clubid: " + club)
       const authorizationtoken = `Bearer ${token}`;
       const headers = {
         'Authorization': authorizationtoken,
         'ClubID': club
       };

      // Make the API request
      const response = await axios.get(DEELNEMER_API_BASE_URL + `/getevenementen/${ID}`, {
        headers: headers
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        console.log("API Error Response:", error.response.data);
        return error.response;
      } else if (error.request) {
        console.log("API Request Error:", error.request);
        // Handle request error
      } else {
        console.log("API Other Error:", error.message);
        // Handle other errors
      }
    }
  }

  async GetDeelnemers(ID) {
    try {
      const token = SessionHandler.getToken();
      const club =  SessionHandler.GetClub();
      console.log("clubid: " + club)
       const authorizationtoken = `Bearer ${token}`;
       const headers = {
         'Authorization': authorizationtoken,
         'ClubID': club
       };

      // Make the API request
      const response = await axios.get(DEELNEMER_API_BASE_URL + `/getdeelnemers/${ID}`, {
        headers: headers
      });

      console.log("API Response:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.log("API Error Response:", error.response.data);
        return error.response;
      } else if (error.request) {
        console.log("API Request Error:", error.request);
        // Handle request error
      } else {
        console.log("API Other Error:", error.message);
        // Handle other errors
      }
    }
  }

  
  async UpdateAangemeld(LidId, Evenenementid, niewestatus) { //Voor wanneer de gebruiken aan of afmeld voor evenement
    try {
      console.log("info:", LidId, Evenenementid, niewestatus); 
      const token = SessionHandler.getToken();
      const club =  SessionHandler.GetClub();
      console.log("clubid:", club);
      
      const authorizationtoken = `Bearer ${token}`;
      const headers = {
        'Authorization': authorizationtoken,
        'ClubID': club
      };
  
      // Prepare the data object to be sent in the request
      const data = {
        lidid: LidId,
        evenementid: Evenenementid,
        status: niewestatus, 
      };
  
      // Make the API request
      const response = await axios.patch(
        `${DEELNEMER_API_BASE_URL}/Aangemeld`, 
        data, // Send the data object directly
        { headers } // Pass headers as the third argument
      );
  
      console.log("API Response:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.log("API Error Response:", error.response.data);
        return error.response;
      } else if (error.request) {
        console.log("API Request Error:", error.request);
        // Handle request error
      } else {
        console.log("API Other Error:", error.message);
        // Handle other errors
      }
    }
  }
  


async UpdateAanwezigheid(LidId, Evenenementid, niewestatus) { //wanneer trainer opkomst van lid noteerd
  try {
    const token = SessionHandler.getToken();
      const club =  SessionHandler.GetClub();
      console.log("clubid: " + club)
       const authorizationtoken = `Bearer ${token}`;
       const headers = {
         'Authorization': authorizationtoken,
         'ClubID': club
       };
       console.log(Evenenementid);
    // Prepare the data object to be sent in the request
    const data = {
      lidid: LidId,
      evenementid: Evenenementid,
      aanwezigheid: niewestatus, // Ensure that the field name matches the API
    };
    // Make the API request
    const response = await axios.patch(
      DEELNEMER_API_BASE_URL + `/aanwezigheid`,
      data,
      { headers }
    );
    console.log("API Response:", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log("API Error Response:", error.response.data);
      return error.response;
    } else if (error.request) {
      console.log("API Request Error:", error.request);
      // Handle request error
    } else {
      console.log("API Other Error:", error.message);
      // Handle other errors
    }
  }
}
}
export default new DeelnemerService()


