import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown"; // Import the Dropdown component
import { Messages } from "primereact/messages";
import SessionHandler from "../../../Sessionhandler/SessionHandler";
import ClubService from "../../../Services/ClubService";
import AccountService from "../../../Services/LidService"; // Import AccountService for account deletion

export default function Groepen() {
  const [clubs, setClubs] = useState([]); // State to hold clubs
  const [selectedClub, setSelectedClub] = useState(null); // State for selected club
  const msgs = useRef(null);

  // Fetch clubs and set the selected club based on lid ID
  const GetClubs = async () => {
    const lidId = SessionHandler.getID(); // Get the lid ID from the session
    try {
      const response = await ClubService.GetFromLid(lidId); // Fetch clubs using the lid ID

      // Check if the response is successful
      if (response.data.type === "SUCCES") {
        // Map clubs to format { label: "Club Name", value: Club ID }
        const clubOptions = response.data.payload.map((club) => ({
          label: club.clubnaam, // Use club name from the payload
          value: club.id, // Use club ID from the payload
        }));

        // Set clubs
        setClubs(clubOptions); // Set the available clubs

        // Get current club from session and convert it to an integer
        const currentClub = SessionHandler.GetClub(); // Convert current club to an integer

        // Check if the current club matches any fetched clubs
        if (clubOptions.some((club) => club.value === currentClub)) {
          setSelectedClub(currentClub); // Set the selected club
        }
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching clubs:", error);
    }
  };

  // Fetch clubs on component mount
  useEffect(() => {
    GetClubs(); // Call the combined fetch and set function
  }, []);

  // Handle club selection change
  const handleClubChange = (e) => {
    const newClubId = e.value; // Get the selected club ID
    setSelectedClub(newClubId); // Update the selected club in state
    SessionHandler.setClub(newClubId); // Set the club ID in the session
    window.location.reload(); // Reload the window
  };

  const Logout = () => {
    SessionHandler.clear();
    window.location.reload();
  };

  // Handle account deletion
  const handleDeleteAccount = async () => {
    if (window.confirm("Weet u zeker dat u uw account wilt verwijderen?")) {
      msgs.current.show({
        severity: "info",
        detail: "Email aan het versturen, dit kan even duren.",
        sticky: true,
      });

      try {
        const response = await AccountService.Delete(); // Assuming deleteAccount API call

        if (response.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "mail verstuurd",
            sticky: true,
          });
        } else {
          for (
            let index = 0;
            index < response.data.payload.details.length;
            ++index
          ) {
            msgs.current.show({
              severity: "error",
              detail: response.data.payload.details[index],
              sticky: true,
            });
          }
        }
      } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };

  return (
    <div style={{ margin: "3vw" }}>
      <Messages ref={msgs} />

      <h1 style={{ marginBottom: "1vw" }}>Instellingen</h1>

      {/* Flexbox container for dropdown and buttons */}
      <div
        className="dropdown-button-container"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "2vw",
          marginBottom: "2vw",
        }}
      >
        {/* Dropdown */}
        {clubs.length > 1 && (
          <div className="dropdown-container" style={{ flexShrink: 0 }}>
            <Dropdown
              id="clubDropdown"
              value={selectedClub}
              options={clubs}
              onChange={handleClubChange}
              placeholder="Select a Club"
            />
          </div>
        )}

        {/* Buttons */}
        <div className="button-container">
          <button
            onClick={() => (window.location.href = "/Settings/AccountSettings")}
            className="btn btn-secondary"
          >
            Account informatie
          </button>
          <button onClick={Logout} className="btn btn-primary logout-button">
            Logout
          </button>
          <button
            onClick={handleDeleteAccount}
            className="btn btn-danger delete-account-button"
          >
            Delete Account
          </button>
        </div>
      </div>

      <br />
    </div>
  );
}
