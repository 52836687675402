import React, { useEffect, useState, useRef } from "react";
import LidService from "../../../Services/LidService";
import GroepService from "../../../Services/GroepService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
import RolService from "../../../Services/RolService";

export default function LedenLijst() {
  const [leden, setLeden] = useState([]);
  const [groepen, setGroepen] = useState([]);
  const [rollen, setRollen] = useState([]);
  const msgs = useRef(null);

  const find = () => {
    setLeden(null);
    setGroepen(null);
    setRollen(null);
    LidService.GetAll().then((result) => setLeden(result.data.payload));
    GroepService.GetAll().then((result) => setGroepen(result.data.payload));
    RolService.GetAll().then((result) => setRollen(result.data.payload));
  };

  useEffect(() => {
    find();
  }, []);

  const onRowEditComplete = (e) => {
    const updatedLeden = [...leden];
    const { newData, index } = e;
    console.log("groep is");
    console.log(newData.groep);

    newData.groep = newData.groep && newData.groep ? newData.groep : null;

    updatedLeden[index] = newData;

    LidService.UpdateClubConnectie(newData).then((result) => {
      if (result.data.type === "SUCCES") {
        setLeden(updatedLeden);
        msgs.current.show({ severity: "success", detail: "Update gelukt!" });
        find();
      } else {
        msgs.current.show({
          severity: "error",
          detail: result.data.payload.details,
          sticky: true,
        });
      }
    });
  };

  const dropdownEditor = (options, items) => {
    const value = options.value ? options.value.id || options.value : null;
    return (
      <Dropdown
        value={value}
        options={[
          { label: "Geen groep", value: null }, // Option for "no group"
          ...items.map((item) => ({ label: item.naam, value: item.id })),
        ]}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Selecteer"
      />
    );
  };

  const dropdownEditor2 = (options, items) => {
    const value = options.value ? options.value.naam || options.value : null;
    return (
      <Dropdown
        value={value}
        options={items.map((item) => ({ label: item.naam, value: item.naam }))}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder="Selecteer"
      />
    );
  };

  const groepBodyTemplate = (rowData) => {
    return rowData.groep ? rowData.groep.naam : ""; // Show empty string when no group is assigned
  };

  const rolBodyTemplate = (rowData) => {
    return rowData.rol ? rowData.rol.naam : "";
  };

  return (
    <>
      <h1 style={{ marginLeft: "3vw" }}>Leden</h1>
      <a style={{ marginLeft: "4vw" }} href="/NieuwLid">
        Nieuw lid
      </a>
      <Messages ref={msgs} />
      <div className="card">
        <DataTable
          value={leden}
          editMode="row"
          dataKey="id"
          paginator
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onRowEditComplete={onRowEditComplete}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          />
          <Column
            field="voornaam"
            header="Voornaam"
            sortable
            filter
            filterPlaceholder="Search"
            style={{ width: "25%" }}
          />
          <Column
            field="achternaam"
            header="Achternaam"
            sortable
            filter
            filterPlaceholder="Search"
            style={{ width: "25%" }}
          />
          <Column
            field="groep"
            header="Groep"
            sortable
            filter
            filterPlaceholder="Search"
            editor={(options) => dropdownEditor(options, groepen)}
            body={groepBodyTemplate}
            style={{ width: "25%" }}
          />
          <Column
            field="email"
            header="Email"
            sortable
            filter
            filterPlaceholder="Search"
            style={{ width: "25%" }}
          />
          <Column
            field="telnummer"
            header="Telefoon nummer"
            sortable
            filter
            filterPlaceholder="Search"
            style={{ width: "25%" }}
          />
          <Column
            field="geboortedatum"
            header="Geboortedatum"
            sortable
            filter
            filterPlaceholder="Search"
            style={{ width: "25%" }}
          />
          <Column
            field="rol"
            header="Rol"
            sortable
            filter
            filterPlaceholder="Search"
            editor={(options) => dropdownEditor2(options, rollen)}
            body={rolBodyTemplate}
            style={{ width: "25%" }}
          />
        </DataTable>
      </div>
    </>
  );
}
