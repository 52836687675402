import React from "react";
import Melden from "./Melden/Melden";

const Homepage = () => {
  return (
    <div>
      <header>
        <h1>Welkom in het aan en afmeld systeem van MTB Oosterhout</h1>
      </header>
      <Melden />
    </div>
  );
};

export default Homepage;
