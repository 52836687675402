import React, { useState, useRef, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import GroepService from "../../../Services/GroepService";
import LidService from "../../../Services/LidService";
import SessionHandler from "../../../Sessionhandler/SessionHandler";

export default function AccountSettings() {
  const [Lid, setLid] = useState({
    voornaam: "",
    achternaam: "",
    adres: "",
    woonplaats: "",
    geboortedatum: "",
    telnummer: "",
    geslacht: "",
    email: "",
    groep: "",
    icenummer1: "",
    icenaam1: "",
    icenummer2: "",
    icenaam2: "",
    emailouders: "",
    id: null,
  });

  const fetchData = async () => {
    try {
      // Fetch the ID from SessionHandler
      const id = await SessionHandler.getID();
      console.log("Fetched ID:", id); // Add this line to debug
      if (!id) {
        throw new Error("ID is undefined or null");
      } else {
        Lid.id = id;
      }

      // Fetch data using the obtained ID
      const result2 = await LidService.GetByID(id);

      const geboortedatumStr = result2.data.payload.geboortedatum;
      const geboortedatumParts = geboortedatumStr.split("-");
      const geboortedatum = new Date(
        geboortedatumParts[2], // Year
        geboortedatumParts[1] - 1, // Month (0-based index)
        geboortedatumParts[0] // Day
      );
      // Update the state with the fetched data
      setLid((prevState) => ({
        ...prevState,
        voornaam: result2.data.payload.voornaam,
        achternaam: result2.data.payload.achternaam,
        adres: result2.data.payload.adres,
        woonplaats: result2.data.payload.woonplaats,
        geboortedatum: geboortedatum,
        telnummer: result2.data.payload.telnummer,
        geslacht: result2.data.payload.geslacht,
        email: result2.data.payload.email,
        groep: result2.data.payload.groep,
        icenummer1: result2.data.payload.icenummer1,
        icenaam1: result2.data.payload.icenaam1,
        icenummer2: result2.data.payload.icenummer2,
        icenaam2: result2.data.payload.icenaam2,
        emailouders: result2.data.payload.emailouders,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (date) => {
    console.log("date =");
    console.log(date);
    if (date != null) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } else return null;
  };

  const msgs = useRef(null);
  const Upload = (e) => {
    e.preventDefault();
    Lid.geboortedatum = formatDate(Lid.geboortedatum);
    Lid.telnummer =
      typeof Lid.telnummer === "string"
        ? Lid.telnummer.replace(/[\D]/g, "")
        : Lid.telnummer;

    Lid.icenummer1 =
      typeof Lid.icenummer1 === "string"
        ? Lid.icenummer1.replace(/[\D]/g, "")
        : Lid.icenummer1;

    Lid.icenummer2 =
      typeof Lid.icenummer2 === "string"
        ? Lid.icenummer2.replace(/[\D]/g, "")
        : Lid.icenummer2;

    LidService.Update(Lid).then((result) => {
      console.log(result);
      try {
        if (result.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "Info geüpdate!",
            sticky: true,
          });
          fetchData();
        } else {
          for (
            let index = 0;
            index < result.data.payload.details.length;
            ++index
          ) {
            msgs.current.show({
              severity: "error",
              detail: result.data.payload.details[index],
              sticky: true,
            });
          }
        }
      } catch {}
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLid((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={(e) => Upload(e)} target="hidden-iframe">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Account Info</h3>
          <div className="text-center"></div>
          <Messages ref={msgs} />
          <div className="form-group mt-3">
            <label>Voornaam</label>
            <InputText
              value={Lid.voornaam}
              name="voornaam"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>

          <div className="form-group mt-3">
            <label>Achternaam</label>
            <InputText
              value={Lid.achternaam}
              name="achternaam"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>

          <div className="form-group mt-3">
            <label>Adres</label>
            <InputText
              value={Lid.adres}
              name="adres"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>
          <div className="form-group mt-3">
            <label>Woonplaats</label>
            <InputText
              value={Lid.woonplaats}
              name="woonplaats"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>
          <div className="form-group mt-3">
            <label>Geboortedatum</label>
            <p className="mt-3" />
            <Calendar
              value={Lid.geboortedatum}
              name="geboortedatum"
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label>Telefoon renner</label>
            <InputMask
              value={Lid.telnummer}
              name="telnummer"
              mask="99-9999-9999"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>
          <div className="form-group mt-3">
            <div>
              <RadioButton
                inputId="Geslacht1"
                name="geslacht"
                value="Man"
                onChange={handleChange}
                checked={Lid.geslacht === "Man"}
              />
              <label htmlFor="Geslacht1" className="ml-2">
                Man
              </label>
            </div>
            <div>
              <RadioButton
                inputId="Geslacht2"
                name="geslacht"
                value="Vrouw"
                onChange={handleChange}
                checked={Lid.geslacht === "Vrouw"}
              />
              <label htmlFor="Geslacht2" className="ml-2">
                Vrouw
              </label>
            </div>
          </div>
          <div className="form-group mt-3">
            <label>Email renner</label>
            <InputText
              value={Lid.email}
              name="email"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>
          <div className="form-group mt-3">
            <label>ICE Naam 1</label>
            <InputText
              value={Lid.icenaam1}
              name="icenaam1"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>

          <div className="form-group mt-3">
            <label>ICE Nummer 1</label>
            <InputMask
              value={Lid.icenummer1}
              name="icenummer1"
              mask="99-9999-9999"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>

          <div className="form-group mt-3">
            <label>ICE Naam 2</label>
            <InputText
              value={Lid.icenaam2}
              name="icenaam2"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>
          <div className="form-group mt-3">
            <label>ICE Nummer 2</label>
            <InputMask
              value={Lid.icenummer2}
              name="icenummer2"
              mask="99-9999-9999"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>

          <div className="form-group mt-3">
            <label>Email Ouder</label>
            <InputText
              value={Lid.emailouders}
              name="emailouders"
              onChange={handleChange}
              className="form-control mt-1"
            />
          </div>

          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Opslaan
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
