import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import EvenementenService from "../../../Services/EvenementenService";
import { Tag } from "primereact/tag";

export default function Evenementen() {
  const [Evenementen, setEvenementen] = useState(null);
  const [ExpendedRows, setExpandedRows] = useState(null);
  const msgs = useRef(null);

  const allowExpansion = (rowData) => {
    try {
      return rowData.deelnemers.length > 0;
    } catch {
      return false;
    }
  };

  const parseDate = (dateString) => {
    if (!dateString || typeof dateString !== "string") {
      throw new Error("Invalid date: Input is not a string");
    }

    // Try parsing standard ISO format
    const isoDate = new Date(dateString);
    if (!isNaN(isoDate.getTime())) {
      return isoDate;
    }

    // Try parsing custom format (DD/MM/YYYY HH:mm:ss)
    const parts = dateString.split(/[/ :]/);
    if (parts.length === 6) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JS
      const year = parseInt(parts[2], 10);
      const hours = parseInt(parts[3], 10);
      const minutes = parseInt(parts[4], 10);
      const seconds = parseInt(parts[5], 10);

      const parsedDate = new Date(year, month, day, hours, minutes, seconds);

      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    }

    throw new Error(`Invalid date format: ${dateString}`);
  };

  const onRowEditComplete = (e) => {
    let _Evenementen = [...Evenementen];
    let { newData, index } = e;
    _Evenementen[index] = newData;
    console.log(newData);
    if (newData.startdatumtijd && newData.einddatumtijd) {
      console.log(newData.startdatumtijd);
      newData.startdatumtijd = parseDate(newData.startdatumtijd).toISOString();
      console.log(newData.startdatumtijd);
      newData.einddatumtijd = parseDate(newData.einddatumtijd).toISOString();
    } else {
      console.error("startdatumtijd or einddatumtijd is missing.");
      return;
    }
    console.log("Sending update request:", newData);

    EvenementenService.Update(newData)
      .then((result) => {
        console.log("Update response:", result);

        if (result.data.type === "SUCCES") {
          setEvenementen(_Evenementen);
          msgs.current.show({
            severity: "success",
            detail: "Update gelukt!",
            sticky: false,
          });
          getEvents();
        } else {
          console.error("Error response:", result.data);
          msgs.current.show({
            severity: "error",
            detail: result.data.payload.details,
            sticky: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error updating event:", error);
        msgs.current.show({
          severity: "error",
          detail: "Update failed. Please try again.",
          sticky: true,
        });
      });
  };

  const GetText = (data) => {
    switch (data.aangemeld) {
      case 0:
        return "Niks Aangegeven";
      case 1:
        return "Aangemeld";
      case 2:
        return "Afgemeld";
      default:
        return "ERROR!!";
    }
  };

  const GetText2 = (data) => {
    switch (data.opkomendagen) {
      case 0:
        return "Niet ingevoerd";
      case 1:
        return "Wel aanwezig";
      case 2:
        return "Niet aanwezig";
      default:
        return "ERROR!!";
    }
  };

  const GetSeverity = (data) => {
    switch (data.aangemeld) {
      case 0:
        return "warning";
      case 1:
        return "success";
      case 2:
        return "danger";
      default:
        return "danger";
    }
  };

  const GetSeverity2 = (data) => {
    switch (data.opkomendagen) {
      case 0:
        return "warning";
      case 1:
        return "success";
      case 2:
        return "danger";
      default:
        return "danger";
    }
  };

  const AanmeldBodyTemplate = (rowData) => {
    return <Tag value={GetText(rowData)} severity={GetSeverity(rowData)}></Tag>;
  };

  const OpdagenBodyTemplate = (rowData) => {
    return (
      <Tag value={GetText2(rowData)} severity={GetSeverity2(rowData)}></Tag>
    );
  };

  const rowExpansionTemplate = (Data) => {
    return (
      <div className="p-3">
        <h5>Aan en afmeldingen voor {Data.titel}</h5>
        <h8>{Data.text}</h8>
        <DataTable value={Data.deelnemers}>
          <Column
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            field="typeDeelnemer"
            header="Type deelnemer"
            sortable
          ></Column>
          <Column field="lid.voornaam" header="Voornaam" sortable></Column>
          <Column field="lid.achternaam" header="Achternaam" sortable></Column>
          <Column
            field="groep.naam"
            header="Groep"
            sortable
            filter
            filterPlaceholder="Search"
          ></Column>
          <Column
            field="opkomendagen"
            header="Opkomen dagen"
            body={OpdagenBodyTemplate}
            sortable
          ></Column>
          <Column
            field="aangemeld"
            header="Aangemeld"
            body={AanmeldBodyTemplate}
            sortable
          ></Column>
          <Column headerStyle={{ width: "4rem" }}></Column>
        </DataTable>
      </div>
    );
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const dataTableSortFunction = (value) => (event) => {
    return [...value].sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];

      // If both values are null
      if (value1 == null && value2 == null) return 0;
      if (value2 == null) return -1;
      if (value1 == null) return 1;

      // Parse the date strings into Date objects
      const parseDate = (dateString) => {
        const [datePart, timePart] = dateString.split(" ");
        const [day, month, year] = datePart
          .split("/")
          .map((num) => parseInt(num, 10));
        const [hour, minute] = timePart
          .split(":")
          .map((num) => parseInt(num, 10));

        return new Date(year, month - 1, day, hour, minute); // month is 0-indexed
      };

      const date1 = parseDate(value1);
      const date2 = parseDate(value2);

      // Compare the dates
      if (date1 < date2) return -1 * event.order;
      if (date1 > date2) return 1 * event.order;

      return 0;
    });
  };

  const getEvents = () => {
    setEvenementen(null);
    EvenementenService.GetAll().then((result) => {
      const formattedEvenementen = result.data.payload.map((event) => {
        const originalStartDate = new Date(event.startdatumtijd);
        const originalEndDate = new Date(event.einddatumtijd);

        const formattedStartDate = `${originalStartDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(originalStartDate.getMonth() + 1)
          .toString()
          .padStart(
            2,
            "0"
          )}/${originalStartDate.getFullYear()} ${originalStartDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${originalStartDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${originalStartDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;
        const formattedEndDate = `${originalEndDate
          .getDate()
          .toString()
          .padStart(2, "0")}/${(originalEndDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${originalEndDate.getFullYear()} ${originalEndDate
          .getHours()
          .toString()
          .padStart(2, "0")}:${originalEndDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${originalEndDate
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;

        return {
          ...event,
          startdatumtijd: formattedStartDate,
          einddatumtijd: formattedEndDate,
        };
      });

      // Sort the Evenementen based on startdatumtijd after formatting
      // janky af but for POC okay
      const sortedEvenementen = formattedEvenementen.sort((event1, event2) => {
        const startDate1 = parseDate(event2.startdatumtijd);
        const startDate2 = parseDate(event1.startdatumtijd);
        return startDate1 - startDate2;
      });

      setEvenementen(sortedEvenementen);
    });
  };

  // Sorting Evenementen initially based on startdatumtijd
  useEffect(() => {
    getEvents();
  }, []);

  const deleteEvent = async (event) => {
    if (window.confirm("Weet u zeker dat het evenement wilt verwijderen?")) {
      msgs.current.show({
        severity: "info",
        detail: "evenement aan het verwijderen",
        sticky: false,
      });

      try {
        const response = await EvenementenService.Delete(event.id); // Assuming deleteAccount API call

        if (response.data.type === "SUCCES") {
          msgs.current.show({
            severity: "success",
            detail: "evenement verwijderd",
            sticky: true,
          });
          getEvents();
        } else {
          for (
            let index = 0;
            index < response.data.payload.details.length;
            ++index
          ) {
            msgs.current.show({
              severity: "error",
              detail: response.data.payload.details[index],
              sticky: true,
            });
          }
        }
      } catch {
        msgs.current.show({
          severity: "error",
          detail:
            "Evenement verwijderen niet gelukt, refresh de pagina en probeer opnieuw.",
          sticky: true,
        });
      }
    }
  };

  const deleteColumn = () => {
    return (
      <Column
        key="delete"
        body={(rowData) => {
          const eventEndDate = parseDate(rowData.einddatumtijd);
          const now = new Date();
          if (eventEndDate > now || eventEndDate < now) {
            //in de toekomst eventEndDate < now verwijderen, dit zorgt er voor dat evenementen in het verleden niet verwijderd kunenn worden in de front-end. dit moet ook in de backend worden verwerkt.
            return (
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                onClick={() => deleteEvent(rowData)}
              />
            );
          }
          return null; // Hide button if event is in the future
        }}
        style={{ width: "6rem", textAlign: "center" }}
      />
    );
  };

  return (
    <>
      <h1 style={{ marginLeft: "3vw" }}>Evenementen</h1>
      <a href="/NieuwEvenement" style={{ marginLeft: "4vw" }}>
        Nieuw evenement
      </a>
      <Messages ref={msgs} />
      <div className="card">
        <DataTable
          onRowEditComplete={onRowEditComplete}
          editMode="row"
          dataKey="id"
          value={Evenementen}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          tableStyle={{ minWidth: "50rem" }}
          expandedRows={ExpendedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          {deleteColumn()}
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          ></Column>
          <Column
            field="titel"
            header="Titel"
            sortable
            filter
            filterPlaceholder="Search"
            editor={textEditor}
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="startdatumtijd"
            header="Startdatum & tijd"
            sortFunction={dataTableSortFunction(Evenementen)}
            sortable
            filter
            filterPlaceholder="Search"
            editor={textEditor}
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="einddatumtijd"
            header="Einddatum & tijd"
            sortable
            sortFunction={dataTableSortFunction(Evenementen)}
            filter
            filterPlaceholder="Search"
            editor={textEditor}
            style={{ width: "25%" }}
          ></Column>
        </DataTable>
      </div>
    </>
  );
}
