import axios from 'axios'
import SessionHandler from '../Sessionhandler/SessionHandler'
axios.defaults.headers.common.Authorization = 'Bearer ' + SessionHandler.getToken()
axios.defaults.headers.common['ClubID'] = SessionHandler.GetClub();
const EVENEMENT_API_BASE_URL = process.env.REACT_APP_API_URL + 'evenement'

class EvenementenService {
  GetAll () {
    return axios.get(EVENEMENT_API_BASE_URL + '/All')
  }


  GetByID(id){
    return axios.get(EVENEMENT_API_BASE_URL + "/Get/" + id)
  }

  async Nieuw (Evenement) {
    console.log(Evenement)
    return await axios({
      method: 'post',
      url: EVENEMENT_API_BASE_URL + '/nieuwevenement',
      headers: {'Content-Type': 'application/json',},
      data: JSON.stringify(Evenement)
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return (error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
  }

 async Delete(evenementID)
 {
    return await axios({
      method: 'delete',
      url: EVENEMENT_API_BASE_URL + '/Delete/' + evenementID,
      headers: {'Content-Type': 'application/json',},
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return (error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
 }



  async Update(Evenement) {
    console.log("Updated Evenement: ", Evenement);

    return await axios({
      method: 'patch',
      url: EVENEMENT_API_BASE_URL + '/Update',
      headers: {'Content-Type': 'application/json',},
      data: JSON.stringify(Evenement)
    }).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        return (error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
}

}
export default new EvenementenService()