import React, { Component } from "react";
import "../../style/Header.css";
import SessionHandler from "../../Sessionhandler/SessionHandler";

class Header extends Component {
  state = {};
  render() {
    if (SessionHandler.GetCurrentRol() === "lid") {
      return (
        <div className="homepage">
          <nav className="header">
            <div className="left">
              <ul className="nav">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/Settings">AccountSettings</a>
                </li>
              </ul>
            </div>
            <div className="right"></div>
          </nav>
        </div>
      );
    } else {
      return (
        <div className="homepage">
          <nav className="header">
            <div className="left">
              <ul className="nav">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/leden">leden</a>
                </li>
                <li>
                  <a href="/evenementen">evenementen</a>
                </li>
                <li>
                  <a href="/Groepen">Groepen</a>
                </li>
                <li>
                  <a href="/Settings">AccountSettings</a>
                </li>
              </ul>
            </div>
            <div className="right"></div>
          </nav>
        </div>
      );
    }
  }
}

export default Header;
