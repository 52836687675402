import React from "react";
import { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Calendar } from "primereact/calendar";
import GroepService from "../../../Services/GroepService";
import LidService from "../../../Services/LidService";
import EvenementenService from "../../../Services/EvenementenService";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

export default function NieuwEvenement() {
  const [Groepen, SetGroepen] = useState(null);
  const [Trainers, SetTrainers] = useState(null);
  const [SelectedGroepen, SetSelectedGroepen] = useState("");
  const [SelectedTrainers, SetSelectedTrainers] = useState("");
  const [SelectedBegeleiders, SetSelectedBegeleiders] = useState("");
  const [HerhalingSwitch, SetHerhaling] = useState(false);
  const [HerhalingTijd, SetHerhalingTijd] = useState("");
  const [OmDe, SetOmDe] = useState(0);
  const [Voor, SetVoor] = useState(0);
  const [Evenement, setEvenement] = useState({
    startdatumtijd: null,
    einddatumtijd: null,
    titel: "",
    text: "",
    groepen: "",
    trainer_ID: "",
  });

  useEffect(() => {
    GroepService.GetAll().then((result) => SetGroepen(result.data.payload));
    LidService.GetAll().then((result) => {
      const trainersWithLabel = result.data.payload.map((trainer) => ({
        ...trainer,
        trainerLabel: `${trainer.voornaam} ${trainer.achternaam}`,
      }));
      SetTrainers(trainersWithLabel);
    });
  }, []);

  const msgs = useRef(null);
  const Upload = (e) => {
    e.preventDefault();

    const eventData = {
      startdatumtijd: Evenement.startdatumtijd,
      einddatumtijd: Evenement.einddatumtijd,
      titel: Evenement.titel,
      text: Evenement.text,
      groepevenement: (SelectedGroepen || []).map((selectedGroup) => ({
        groep: selectedGroup.id,
        trainers:
          (SelectedTrainers[selectedGroup.naam] || []).map(
            (trainer) => trainer.id
          ) || [],
        ouderbegeleider:
          (SelectedBegeleiders[selectedGroup.naam] || []).map(
            (begeleider) => begeleider.id
          ) || [],
      })),
    };

    if (HerhalingSwitch) {
      eventData.herhaling = eventData.Herhaling || {};
      if (HerhalingTijd != null && HerhalingTijd == "Maand(en)") {
        eventData.herhaling.herhalingtijd = "maand";
        eventData.herhaling.omde = OmDe;
        eventData.herhaling.keer = Voor;
      }
      if (HerhalingTijd != null && HerhalingTijd == "Week(en)") {
        eventData.herhaling.herhalingtijd = "week";
        eventData.herhaling.omde = OmDe;
        eventData.herhaling.keer = Voor;
      }
    }

    // Log the formatted data (you can replace this with your actual API call)
    console.log(JSON.stringify(eventData, null, 2));

    EvenementenService.Nieuw(eventData, OmDe, Voor, HerhalingTijd).then(
      (result) => {
        console.log(result);
        try {
          if (result.data.type === "SUCCES") {
            msgs.current.show({
              severity: "success",
              detail: "Evenemenent toevoegen gelukt!",
              sticky: true,
            });
          } else {
            for (
              let index = 0;
              index < result.data.payload.details.length;
              ++index
            ) {
              msgs.current.show({
                severity: "error",
                detail: result.data.payload.details[index],
                sticky: true,
              });
            }
          }
        } catch {}
      }
    );

    // Reset the form or handle the actual submission as needed
    // e.target.reset();
  };

  const Frequentie = ["Week(en)", "Maand(en)"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvenement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <form onSubmit={(e) => Upload(e)} target="hidden-iframe">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Nieuw Evenement</h3>
          <div className="text-center"></div>
          <Messages ref={msgs} />
          <div className="form-group mt-3">
            <label>titel</label>
            <InputText
              name="titel"
              onChange={handleChange}
              className="form-control mt-1"
              placeholder="Enter titel"
            />
          </div>

          <div className="form-group mt-3">
            <label>text</label>
            <InputText
              name="text"
              onChange={handleChange}
              className="form-control mt-1"
              placeholder="Enter text"
            />
          </div>

          <div className="form-group mt-3">
            <label>start datum & tijd</label>
            <p className="mt-3" />
            <Calendar
              name="startdatumtijd"
              onChange={handleChange}
              placeholder="Enter start datum tijd"
              showTime
              hourFormat="24"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="form-group mt-3">
            <label>eind datum & tijd</label>
            <p className="mt-3" />
            <Calendar
              name="einddatumtijd"
              onChange={handleChange}
              placeholder="Enter eind datum tijd"
              showTime
              hourFormat="24"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="form-group mt-3">
            <label>Groepen</label>
            <MultiSelect
              name="groep"
              onChange={(e) => SetSelectedGroepen(e.value)}
              value={SelectedGroepen}
              optionLabel="naam"
              options={Groepen}
              className="form-control mt-1"
              placeholder="selecteer groep"
            />
          </div>
          <div className="from-group mt-3">
            <label>Herhaling?</label>
            <p className="mt-3" />
            <InputSwitch
              checked={HerhalingSwitch}
              onChange={(e) => SetHerhaling(e.value)}
            />
          </div>
          {HerhalingSwitch && (
            <div
              style={{
                border: "1px solid black",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <h2>Herhaling Settings</h2>
              <label>Om de week voor 4x is: 2, week(en), 4</label>
              <br />
              <label>Elke Maand is voor 5x: 1, maand(en), 5</label>
              <div className="form-group mt-3">
                <label>Elke</label>
                <InputNumber
                  value={OmDe}
                  onValueChange={(e) => SetOmDe(e.value)}
                  min={1}
                  max={50}
                  className="form-control mt-1"
                />
              </div>
              <div className="form-group mt-3">
                <Dropdown
                  name="Frequentie"
                  options={Frequentie}
                  onChange={(e) => SetHerhalingTijd(e.value)}
                  placeholder="Selecteer frequentie"
                  value={HerhalingTijd}
                  className="form-control mt-1"
                />
              </div>
              <div className="form-group mt-3">
                <label>Voor x keer</label>
                <InputNumber
                  value={Voor}
                  onValueChange={(e) => SetVoor(e.value)}
                  min={1}
                  max={52}
                  className="form-control mt-1"
                />
              </div>
            </div>
          )}

          {SelectedGroepen.length > 0 && (
            <div className="form-group mt-3">
              {SelectedGroepen.map((selectedGroup) => (
                <div
                  key={selectedGroup.naam}
                  className="mt-2"
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <h2>{selectedGroup.naam}</h2>
                  <br />
                  <label>{`Trainers`}</label>
                  <div className="form-group mt-3">
                    <MultiSelect
                      value={SelectedTrainers[selectedGroup.naam] || []}
                      optionLabel="trainerLabel"
                      filter
                      options={Trainers}
                      onChange={(e) =>
                        SetSelectedTrainers((prevSelectedTrainers) => ({
                          ...prevSelectedTrainers,
                          [selectedGroup.naam]: e.value,
                        }))
                      }
                      placeholder="Selecteer trainer"
                    />
                  </div>
                  <br />
                  <label>{`Begeleider`}</label>
                  <div classname="form-group mt-3">
                    <MultiSelect
                      value={SelectedBegeleiders[selectedGroup.naam] || ""}
                      optionLabel="trainerLabel"
                      filter
                      options={Trainers}
                      onChange={(e) =>
                        SetSelectedBegeleiders((prevSelectedBegeleiders) => ({
                          ...prevSelectedBegeleiders,
                          [selectedGroup.naam]: e.value,
                        }))
                      }
                      placeholder="Selecteer begeleider"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              voeg toe
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
